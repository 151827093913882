.excalidraw {
  .virgil {
    font-family: "WxChinese";
    //font-size: 24px;
    //font-family: "Virgil";
  }

  .WelcomeScreen-logo {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    font-size: 2.25rem;
    font-weight: 800;
    font-family: "WxChinese", serif;

    svg {
      width: 1.625rem;
      height: auto;
    }
  }

  .WelcomeScreen-decor {
    pointer-events: none;

    color: var(--color-gray-40);

    &--subheading {
      font-size: 1.125rem;
      text-align: center;
    }

    &--help-pointer {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 100%;

      :root[dir="rtl"] & {
        left: 0;
        right: auto;
      }

      svg {
        margin-top: 0.5rem;
        width: 85px;
        height: 71px;

        transform: scaleX(-1) rotate(80deg);

        :root[dir="rtl"] & {
          transform: rotate(80deg);
        }
      }
    }

    &--top-toolbar-pointer {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 2.5rem;
      display: flex;
      align-items: baseline;

      &__label {
        width: 120px;
        position: relative;
        top: -0.5rem;
      }

      svg {
        width: 38px;
        height: 78px;

        :root[dir="rtl"] & {
          transform: scaleX(-1);
        }
      }
    }

    &--menu-pointer {
      position: absolute;
      width: 320px;
      font-size: 1rem;

      top: 100%;
      margin-top: 0.25rem;
      margin-inline-start: 0.6rem;

      display: flex;
      align-items: flex-end;
      gap: 0.5rem;

      svg {
        width: 41px;
        height: 94px;

        :root[dir="rtl"] & {
          transform: scaleX(-1);
        }
      }
    }
  }

  .WelcomeScreen-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    pointer-events: none;
    left: 1rem;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
  }

  .WelcomeScreen-items {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
  }

  .WelcomeScreen-item {
    box-sizing: border-box;

    pointer-events: all;

    color: var(--color-gray-50);
    font-size: 0.875rem;

    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: none;
    border: none;

    padding: 0.75rem;

    border-radius: var(--border-radius-md);

    &__label {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      svg {
        width: var(--default-icon-size);
        height: var(--default-icon-size);
      }
    }

    &__shortcut {
      color: var(--color-gray-40);
      font-size: 0.75rem;
    }
  }

  &:not(:active) .WelcomeScreen-item:hover {
    text-decoration: none;
    background: var(--color-gray-10);

    .WelcomeScreen-item__shortcut {
      color: var(--color-gray-50);
    }

    .WelcomeScreen-item__label {
      color: var(--color-gray-100);
    }
  }

  .WelcomeScreen-item:active {
    background: var(--color-gray-20);

    .WelcomeScreen-item__shortcut {
      color: var(--color-gray-50);
    }

    .WelcomeScreen-item__label {
      color: var(--color-gray-100);
    }

    &--promo {
      color: var(--color-promo) !important;

      &:hover {
        .WelcomeScreen-item__label {
          color: var(--color-promo) !important;
        }
      }
    }
  }

  &.theme--dark {
    .WelcomeScreen-decor {
      color: var(--color-gray-60);
    }

    .WelcomeScreen-item {
      color: var(--color-gray-60);

      &__shortcut {
        color: var(--color-gray-60);
      }
    }

    &:not(:active) .WelcomeScreen-item:hover {
      background: var(--color-gray-85);

      .WelcomeScreen-item__shortcut {
        color: var(--color-gray-50);
      }

      .WelcomeScreen-item__label {
        color: var(--color-gray-10);
      }
    }

    .WelcomeScreen-item:active {
      background-color: var(--color-gray-90);
      .WelcomeScreen-item__label {
        color: var(--color-gray-10);
      }
    }
  }

  // Can tweak these values but for an initial effort, it looks OK to me
  @media (max-width: 1024px) {
    .WelcomeScreen-decor {
      &--help-pointer,
      &--menu-pointer {
        display: none;
      }
    }
  }

  // @media (max-height: 400px) {
  //   .WelcomeScreen-container {
  //     margin-top: 0;
  //   }
  // }
  @media (max-height: 599px) {
    .WelcomeScreen-container {
      margin-top: 4rem;
    }
  }
  @media (min-height: 600px) and (max-height: 900px) {
    .WelcomeScreen-container {
      margin-top: 8rem;
    }
  }
  @media (max-height: 630px) {
    .WelcomeScreen-decor--top-toolbar-pointer {
      display: none;
    }
  }
  @media (max-height: 500px) {
    .WelcomeScreen-container {
      display: none;
    }
  }

  // @media (max-height: 740px) {
  //   .WelcomeScreen-decor {
  //     &--help-pointer,
  //     &--top-toolbar-pointer,
  //     &--menu-pointer {
  //       display: none;
  //     }
  //   }
  // }
}
